import { BoxProps, Box as MuiBox } from "@mui/system";
import { NO_TRANSLATE_CLASS } from "../../types/localize-types";

type NoTranslateProps = Pick<BoxProps, "sx" | "children"> & {
  /**
   * The HTML component to use for the <NoTranslate /> component itself.
   * Useful when a div is not allowed by DOM-nesting rules.
   * Note: If you are trying to avoid translating parts of a phrase, use <TranslateVar /> instead.
   *
   * @default div
   */
  // Note: The large union type of BoxProps["component"] currently crashes the Viewer's type resolution.
  // Allowing only the most needed components for now.
  component?: "div" | "span";
};

/**
 * @returns A component whose children will not be translated by localizeJS
 */
export function NoTranslate({
  children,
  sx,
  component = "div",
}: NoTranslateProps): JSX.Element {
  return (
    <MuiBox component={component} sx={sx} className={NO_TRANSLATE_CLASS}>
      {children}
    </MuiBox>
  );
}
