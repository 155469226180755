#include <packing>

varying vec2 vUv;

uniform sampler2D map;

uniform float opacity;
uniform float uBackgroundTransparent;
uniform mat4 projectionMatrix;

#include <clipping_planes_pars_fragment>
#include <lotv_color_conversions>

in vec4 mvPosition;

void main() {
    #include <clipping_planes_fragment>
    vec4 texColor = texture2D(map, vUv);

    float alpha = 1.0;
    if (uBackgroundTransparent != 0.0) {
        // Calculate relative luminance
        float luminance = dot(texColor.rgb, extractLuma);
        if (uBackgroundTransparent == 1.0) {
            // Invert luminance to set alpha (white -> 0.0, black -> 1.0)
            alpha = 1.0f - luminance;
        } else {
            // Apply luminance threshold, alpha = 1 to pixels with luminance < 0.75
            alpha = min(1.0, 4.0 * (1.0 - luminance));
        }
    }

    // Combine the transparency to preserve completely transparent sections of the image
    // while having a smooth transition
    gl_FragColor = texColor;
    gl_FragColor.a = alpha * texColor.a * opacity;

    // If the fragment is almost transparent, we do not want it to occlude the measurement lines.
    // We could discard fragments with alpha lower than 0.05, but this would result in fragments
    // from leaf tiles not passing the stencil test, thus rendering fragments from parent tiles,
    // and causing blocky artifacts in the tiled overview maps.
    // To prevent these blocky artifacts, we set the depth of almost transparent fragments to 1.0,
    // so they still pass the stencil test, occluding parent tiles, but their depth does not occlude
    // the measurement lines.
    vec4 clipPosition = projectionMatrix * mvPosition;
    float depth = (clipPosition.z / clipPosition.w) * 0.5 + 0.5;

    gl_FragDepth = gl_FragColor.a < 0.05 ? 1.0 : depth;
}
