import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import { PointCloudChunk } from "../PointCloud/PointCloudChunk";
import { VisibleNodesStrategy } from "./VisibleNodeStrategy";

/**
 * Status of a node fetch
 */
export type LodNodeFetch = {
	/** Unique id of the node fetched  */
	readonly uuid: string;
	/** Wait for the points to arrive */
	points(): Promise<PointCloudChunk>;
	/** Abort this fetch, points() promise will be rejected */
	abort(): void;
};

/** Mutually exclusive states for a node. */
export enum NodeState {
	NotInUse = 0,
	WaitingForDownload = 1,
	Downloading = 2,
	InUse = 3,
}

/**
 * A single node of the lod tree
 */
export type LodTreeNode = {
	/** Unique number for this node in the three */
	readonly id: number;
	/** Bounding box for the node */
	readonly boundingBox: Box3;
	/** Id of the parent of this node, undefined if this node is the root */
	readonly parentId?: number;
	/** Children of this node, may be empty if this node is a leaf */
	readonly children: LodTreeNode[];
	/** Number of points in this node */
	readonly numPoints: number;
	/**
	 * The meaning of this value is implementation defined. For Webshare, it is the density
	 * of points for this node as the distance in millimeters between two consecutive points.
	 * For Potree, it is a coefficient used to calculate the point density in meters per point,
	 * inside the AdaptivePointsMaterial.
	 */
	readonly pointDensity: number;
	/** The most recently calculated number of pixels that this node represents */
	pixelsPerPoint: number;
	/** Depth of this node in the tree */
	readonly depth: number;
	/** Unique id of this node, implementation defined */
	readonly uuid: string;
	/** State of a node */
	state: NodeState;
};

/**
 * Generic lod tree
 */
export type LodTree<Node extends LodTreeNode = LodTreeNode> = {
	/** Unique identification for this three, implementation defined  */
	readonly uuid: string;
	/** The root node of the tree */
	readonly root: Node;
	/** Pose of the entire tree in world space */
	readonly worldMatrix: Matrix4;
	/** Inverse pose of the entire tree in world space */
	readonly worldMatrixInverse: Matrix4;
	/** Position of the entire tree in world space */
	readonly position: Vector3;
	/** The tree bounding box, strictly containing all the points */
	readonly boundingBox: Box3;
	/** Rotation of the entire tree in world space */
	readonly quaternion: Quaternion;
	/** Max depth of the tree */
	readonly maxDepth: number;
	/** Total number of nodes in the tree */
	readonly numNodes: number;
	/** The strategy to use for computing visible nodes for this tree. */
	visibleNodesStrategy: VisibleNodesStrategy;

	/**
	 * Get the node with a specific id
	 *
	 * @param id The id of the node
	 * @returns the node
	 */
	getNode(id: number): Node;
	/**
	 * Fetch the points for a specific node
	 *
	 * @param nodeOrId The id of the node or the node itself
	 * @returns an object to wait or cancel the download
	 */
	getNodePoints(nodeOrId: Node | number): Promise<LodNodeFetch>;
	/**
	 * Sets the pose of the tree in the world coordinate system.
	 * This method affects the 'worldMatrix' and 'worldMatrixInverse'
	 * properties
	 *
	 * @param m The new global pose matrix. It must be a rigid transformation.
	 */
	setWorldMatrix(m: Matrix4): void;
};
