import { assert } from "./assert";

export type RetryOptions = {
  /** Max number of retries (Default: 10) */
  max?: number;

  /** Delay between one retry an the next one in milliseconds (Default: 1000) */
  delay?: number | ((iteration: number, lastDelay?: number) => number);

  /** Function to decide if an error can be retried, true to retry (Default: always retry) */
  shouldRetry?(reason: unknown): boolean;
};

/**
 * Execute an async function and retry if failed
 *
 * @param f The async function to execute
 * @param options to configure how to handle the retry
 * @returns the value returned by f when it succeeded or the last failure
 */
export async function retry<
  F extends () => Promise<R>,
  R = Awaited<ReturnType<F>>,
>(f: F, options: RetryOptions = {}): Promise<R> {
  const { max = 10, shouldRetry = () => true, delay = 1000 } = options;

  assert(max > 0, "Invalid number of max retries, need to be at least 1");
  assert(
    typeof delay !== "number" || delay > 0,
    "Invalid delay, need to be at least 1",
  );

  // This loop capture all tries until the last
  let effectiveDelay: number | undefined = undefined;
  for (let i = 0; i < max - 1; ++i) {
    effectiveDelay = Math.max(
      typeof delay === "number" ? delay : delay(i + 1, effectiveDelay),
      1,
    );
    try {
      return await f();
    } catch (reason) {
      if (!shouldRetry(reason)) {
        throw reason;
      }
    }
    await new Promise((resolve) =>
      globalThis.setTimeout(resolve, effectiveDelay),
    );
  }

  // If we reach here this is the last chance so it's ok to just invoke the function
  return f();
}

/** Max waiting time on exponential back-off */
export const MAX_BACK_OFF_MS = 64000;

/**
 * A function compatible with the retry delay parameters to implement exponential back-off
 *
 * Reference: https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 *
 * @param iteration retry iteration
 * @param lastDelay the delay used for the last iteration if available
 * @returns how long to wait for the next try
 */
export function exponentialBackOff(
  iteration: number,
  lastDelay?: number,
): number {
  if (lastDelay === MAX_BACK_OFF_MS) return lastDelay;
  return Math.min(
    Math.pow(2, iteration) + Math.random() * 1000,
    MAX_BACK_OFF_MS,
  );
}
