import {
  IElement,
  IElementGenericPointCloud,
  IElementGenericPointCloudStream,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
  isIElementWithTypeAndHints,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "../i-elements-selectors";
import { State } from "../i-elements-slice";

/**
 * List of known Point Cloud types.
 */
export enum PointCloudType {
  /** A higher quality, stationary scan of a mobile device. */
  flash = "flash",
  /** A non-stationary scan, generally with lower quality. */
  mobile = "mobile",
  /** A merged and post-processed point cloud generated from individual scans */
  project = "project",
  /** A point cloud for an individual scan */
  singleScan = "singleScan",
  /** General point cloud scan. */
  other = "other",
}

/** Type hints used to mark sections containing data of an individual scan */
const SINGLE_SCAN_TYPE_HINTS = [
  /** Scan produced by a FARO Focus device */
  IElementTypeHint.focusScan,

  /** Scan produced by a FARO ELS/Blink device */
  IElementTypeHint.elsScan,

  /** Generic (third-party) scan with a pano */
  IElementTypeHint.structuredScan,

  /** Generic (third-party) scan */
  IElementTypeHint.unstructuredScan,

  /** WebShare migration scan */
  IElementTypeHint.scanWs,
];

/**
 * @param pointCloud The point cloud to determine the type of.
 * @returns The type of scan technique used to create the point cloud.
 */
export function selectPointCloudType(
  pointCloud: IElementGenericPointCloudStream | IElementGenericPointCloud,
) {
  return (state: State) => {
    const isFlash = !!selectAncestor(pointCloud, (el) =>
      isIElementWithTypeAndHint(
        el,
        IElementType.section,
        IElementTypeHint.flash,
      ),
    )(state);

    // ASSUMPTION: GeoSlam datasets contain either flash scans or the mobile scans
    const isMobile =
      !isFlash &&
      !!selectAncestor(pointCloud, (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetGeoSlam,
        ),
      )(state);

    const isSingleScan = !!selectAncestor(
      pointCloud,
      isSingleScanSection,
    )(state);

    const isProjectPointCloud = !!selectAncestor(
      pointCloud,
      (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetFocus,
        ) ||
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetEls,
        ),
    )(state);

    if (isFlash) {
      return PointCloudType.flash;
    }

    if (isMobile) {
      return PointCloudType.mobile;
    }

    if (isSingleScan) {
      return PointCloudType.singleScan;
    }

    if (isProjectPointCloud) {
      return PointCloudType.project;
    }

    return PointCloudType.other;
  };
}

/**
 * @returns true if an element is the section of a single scan
 * @param el The element to check
 */
export function isSingleScanSection(el: IElement): el is IElementSection {
  return isIElementWithTypeAndHints(
    el,
    IElementType.section,
    SINGLE_SCAN_TYPE_HINTS,
  );
}
