import { assert } from "@faro-lotv/foundation";
import { Camera, Intersection, Matrix4, OrthographicCamera, PerspectiveCamera, Ray, Raycaster, Vector3 } from "three";
import { FrustumBoxCheck, frustumIntersectsBox } from "../Algorithms/FrustumIntersection";
import { PointCloudRaycastingOptions } from "../PointCloud/PointCloud";
import { DEG_TO_RAD } from "../Utils/Math";
import { ClipPlanesStatus } from "./ClipPlanesStatus";
import { LodTree, LodTreeNode } from "./LodTree";
import { PointsCacheElement } from "./PointsCacheElement";

/**
 * Custom raycasting options for an LodPointCloud
 */
export type LodPointCloudRaycastingOptions = PointCloudRaycastingOptions & {
	/** Max depth of the LodTree to use for raycasting */
	maxDepth: number;

	/** True to return only the closest point to the camera from the raycasting */
	shouldReturnOnlyClosest: boolean;

	/**
	 * The maximum number of new picking trees that can be created for each pick operation.
	 * The total number picking trees remains unchanged
	 */
	maxPickingTreesPerRaycast: number;

	/** Max time (milliseconds) that can be spent in a single raycast invocation when doing real time raycasting */
	realtimeBudget: number;
};

/**
 * Default raycast settings for lod point cloud, geared toward low memory usage and fast, not super precise, raycasting
 */
export const LOD_POINT_CLOUD_RAYCASTING_DEFAULTS: Required<LodPointCloudRaycastingOptions> = {
	enabled: true,
	threshold: 0.05,
	pickingTree: {
		enabled: false,
		autoUpdate: true,
		maxDepth: 6,
	},
	maxDepth: 4,
	shouldReturnOnlyClosest: false,
	maxPickingTreesPerRaycast: Number.MAX_SAFE_INTEGER,
	realtimeBudget: 1,
};

type Distance2PixSizeFnc = (distance: number) => number;

/**
 * Class with capabilities to raycast a LOD point cloud. It is meant to use only
 * within the Lotv library.
 */
export class LodPointCloudRaycast {
	/** Set to true to allow the raycasting to return imprecise results but to guarantee realtime performances */
	realTimeRaycasting = true;
	/** Cached local space ray to not re-allocate at each raycast */
	#localRay = new Ray();
	/** Cached raycast target to not re-allocate at each raycast */
	#raycastTarget = new Vector3();
	/** Buffer to store raycast `Intersections` at each call, used to avoid creating the object repeatedly */
	#localIntersects = new Array<Intersection>();
	/** Buffer to store raycast results that may also fall outside the clipping box */
	#preClippingIntersects = new Array<Intersection>();
	/** Screen height in pixels */
	#screenHeightPixels = 1000;
	/** Camera's projection parameter (fov or ortho vsize) */
	#cameraFov = -1;
	/**
	 * Coefficient to compute how many meters measures a pixel given a distance from a camera.
	 * To simplify the computation, we accept the assumption that the pixel is always at the center of the screen.
	 */
	#distance2pix = 1;

	private distance2pixSizePersp(distance: number): number {
		return distance * this.#distance2pix;
	}

	private distance2pixSizeOrtho(): number {
		return this.#distance2pix;
	}

	private distance2pixSize: Distance2PixSizeFnc;

	#updateCamera(camera: Camera): void {
		if (camera instanceof PerspectiveCamera) {
			this.distance2pixSize = this.distance2pixSizePersp;
			const fov = camera.getEffectiveFOV();
			if (this.#cameraFov !== fov) {
				this.#cameraFov = fov;
				this.#distance2pix = Math.tan(fov * DEG_TO_RAD * 0.5) / (this.#screenHeightPixels * 0.5);
			}
		} else if (camera instanceof OrthographicCamera) {
			this.distance2pixSize = this.distance2pixSizeOrtho;
			const vsize = (camera.top - camera.bottom) / camera.zoom;
			if (this.#cameraFov !== vsize) {
				this.#cameraFov = vsize;
				this.#distance2pix = vsize / this.#screenHeightPixels;
			}
		}
	}

	/** Sets the current viewport height, in pixels. */
	set screenHeightPixels(value: number) {
		if (value !== this.#screenHeightPixels) {
			this.#screenHeightPixels = value;
			// The line below triggers the recomputation of the distance2pixSize function
			this.#cameraFov = -1;
		}
	}

	/**
	 *
	 * @param tree the Lod Tree to raycast
	 * @param nodesInGPU Nodes with their points for raycasting
	 * @param clipStatus clip planes status
	 */
	constructor(
		private tree: LodTree,
		private nodesInGPU: Map<number, PointsCacheElement>,
		private clipStatus: ClipPlanesStatus,
	) {
		this.distance2pixSizePersp = this.distance2pixSizePersp.bind(this);
		this.distance2pixSizeOrtho = this.distance2pixSizeOrtho.bind(this);
		this.distance2pixSize = this.distance2pixSizePersp;
	}

	/**
	 * Fast algo to raycast the LOD point cloud.
	 *
	 * @param raycaster - The raycaster to use for raycasting
	 * @param intersects - The array to store the intersections in
	 * @param raycasting - The raycasting options to use
	 * @param worldInv - The inverse of the world matrix of the point cloud
	 */
	raycast(
		raycaster: Raycaster,
		intersects: Intersection[],
		raycasting: Required<LodPointCloudRaycastingOptions>,
		worldInv: Matrix4,
	): void {
		const startTime = performance.now();

		// There is a mistake in 3js types: 'raycaster.camera' can also be null. Hence the lint disable below.
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (raycaster.camera) {
			this.#updateCamera(raycaster.camera);
		}

		this.#localRay.copy(raycaster.ray).applyMatrix4(worldInv);
		const clipping = this.clipStatus.isClipping;

		// Store the initial threshold value as we well update it depending on the density of the first leaf node
		const defThreshold = raycasting.threshold;
		let computedPickingTrees = 0;
		// keeping track of the closest intersection found to the camera, and of its distance to the camera
		let minDistance = Number.POSITIVE_INFINITY;
		let closestIntersectionIdx = -1;

		this.#localIntersects.length = 0;

		/**
		 * Recursive function to pick on nodes using our heuristics
		 *
		 * @param node The node to check
		 * @param clippingHint hint about whether the node is inside, intersecting, or outside the clipping box
		 * @param distanceToCamera distance of the node to the camera, to determine the correct point-ray threshold
		 */
		const depthFirstSinglePickRaycast = (
			node: LodTreeNode,
			clippingHint: FrustumBoxCheck,
			distanceToCamera: number,
		): void => {
			// If we passed the performance time budget bail out
			if (this.realTimeRaycasting && performance.now() - startTime >= raycasting.realtimeBudget) return;
			// If we reached max depth bail out
			if (node.depth > raycasting.maxDepth) return;
			// If we don't intersect this node bail out
			if (!this.#localRay.intersectBox(node.boundingBox, this.#raycastTarget)) return;

			if (clippingHint !== FrustumBoxCheck.BoxInside) {
				clippingHint = frustumIntersectsBox(this.clipStatus.localFrustum, node.boundingBox);
				// If the clipping box is active and this node is outside of the box, bail out.
				if (clippingHint === FrustumBoxCheck.BoxOutside) return;
			}

			// Sort children by distance from the ray.
			// Computing the list of the node's children sorted by distance to the camera. In this way
			// the `distanceToPoint` function is executed only once per child, saving NlogN square roots.
			// The array below cannot be allocated outside this function, because otherwise the child node's array
			// overwrites the parent node's array.
			// Furthermore, the camera->node distance is used to compute a suitable point-ray threshold for raycasting.
			const sortedChildren = new Array<{ idx: number; distanceToPoint: number }>(node.children.length);
			for (let c = 0; c < node.children.length; ++c) {
				sortedChildren[c] = {
					idx: c,
					distanceToPoint: node.children[c].boundingBox.distanceToPoint(this.#localRay.origin),
				};
			}
			sortedChildren.sort((a, b) => a.distanceToPoint - b.distanceToPoint);

			if (raycasting.shouldReturnOnlyClosest) {
				for (const child of sortedChildren) {
					if (minDistance < child.distanceToPoint) break;
					// The parent node passes the clipping hint to the children, because
					// if the parent was inside the clipping box, also the children will be inside
					depthFirstSinglePickRaycast(node.children[child.idx], clippingHint, child.distanceToPoint);
				}
			} else {
				for (const child of sortedChildren) {
					depthFirstSinglePickRaycast(node.children[child.idx], clippingHint, child.distanceToPoint);
				}
			}

			const points = this.nodesInGPU.get(node.id)?.points;
			if (points) {
				const origOptions = points.raycasting;
				points.raycasting = raycasting;

				// Limit the number of picking tree we can compute for every loop
				const needToComputePickingTree = points.pickingTree === undefined && raycasting.pickingTree.enabled;
				if (needToComputePickingTree) {
					if (this.realTimeRaycasting && computedPickingTrees >= raycasting.maxPickingTreesPerRaycast) {
						points.raycasting = origOptions;
						return;
					}

					computedPickingTrees += 1;
				}

				// If the node is very far away from the perspective camera, then the point-ray distance threshold
				// needs to be increased to compensate for the lack of mouse resolution at that distance.
				const oldThreshold = raycasting.threshold;
				raycasting.threshold = Math.max(this.distance2pixSize(distanceToCamera) * 2, defThreshold);

				const oldIntrCount = this.#localIntersects.length;
				// Updating the index to the closest intersection found so far.
				if (clippingHint === FrustumBoxCheck.BoxIntersects) {
					this.#preClippingIntersects.length = 0;
					points.raycast(raycaster, this.#preClippingIntersects);
					for (const i of this.#preClippingIntersects) {
						if (this.clipStatus.worldFrustum.containsPoint(i.point)) {
							this.#localIntersects.push(i);
							if (i.distance < minDistance) {
								minDistance = i.distance;
								closestIntersectionIdx = this.#localIntersects.length - 1;
							}
						}
					}
				} else {
					// If we enter here, all points are for sure inside the clipping box.
					points.raycast(raycaster, this.#localIntersects);
					for (let i = oldIntrCount; i < this.#localIntersects.length; ++i) {
						if (this.#localIntersects[i].distance < minDistance) {
							minDistance = this.#localIntersects[i].distance;
							closestIntersectionIdx = i;
						}
					}
				}
				raycasting.threshold = oldThreshold;
				points.raycasting = origOptions;
			}
		};

		// If there is a clipping box, we pass 'BoxIntersects' as clipping hint because is the most general,
		// so the actual intersection is computed.
		depthFirstSinglePickRaycast(
			this.tree.root,
			clipping ? FrustumBoxCheck.BoxIntersects : FrustumBoxCheck.BoxInside,
			0,
		);

		// Restore the threshold to the original value
		raycasting.threshold = defThreshold;

		// if no intersections, early return
		if (this.#localIntersects.length === 0) return;

		// If only the closest point is needed, no further sorting operations are needed.
		if (raycasting.shouldReturnOnlyClosest) {
			// When returning only the closest point optimize to get the closest to the ray in
			// a 100 mm range from the first hit and not only the closest to the camera
			const maxDistance = minDistance + 0.1;
			assert(closestIntersectionIdx >= 0);
			let bestIntersect = this.#localIntersects[closestIntersectionIdx];
			assert(bestIntersect.distanceToRay !== undefined);
			let bestDistanceToRay: number = bestIntersect.distanceToRay;
			for (const intersection of this.#localIntersects) {
				if (
					intersection.distance < maxDistance &&
					intersection.distanceToRay !== undefined &&
					intersection.distanceToRay < bestDistanceToRay
				) {
					bestIntersect = intersection;
					bestDistanceToRay = intersection.distanceToRay;
				}
			}
			this.#localIntersects[0] = bestIntersect;
			this.#localIntersects.length = 1;
		} else {
			// If a list of intersections is requested, a last sorting of the intersections is performed
			// to return them ordered by distance to the camera position.
			this.#localIntersects.sort((a, b) => a.distance - b.distance);
		}

		intersects.push(...this.#localIntersects);
	}
}
